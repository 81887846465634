/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import {FINETUNED, GridMetric, GridProperty, MetricsSummary, PerformerName} from '../../definitions/metrics';
import {nest} from 'd3-collection';
import {performerTheme} from '../../styles/performerTheme';
import React, {useState, useRef} from 'react';
import {Button, Popover, Tooltip} from 'antd';
import {SettingOutlined} from '@ant-design/icons';
import {MetricsSettingsModal} from './MetricsSettingsModal';
import {useDataContext} from '../../context/DataContext';
import {ErrorBoundary} from '../ErrorBoundary';
import {CameraOutlined} from '@ant-design/icons';
import {exportComponentAsPNG} from 'react-component-export-image';
import {HeaderText} from '../Legend/Shared';

export interface MetricsGridProps {
  data: GridMetric[];
  columnLabels?: Record<string, string>;
  columnProperty?: GridProperty; // Default is PerformerName
}

export const MetricsGrid: React.FC<MetricsGridProps> = ({
  data,
  columnLabels,
  columnProperty = 'PerformerName',
}: MetricsGridProps) => {
  const [
    {metricsSummary, primaryMetrics, defaultDisplayMetrics, performerTeams, teamSystems},
    {getSystemColor, getPatternColor, getReverseMetric},
  ] = useDataContext();
  const groupedData = getGroupedData(data);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [visibleMetrics, setVisibleMetrics] = useState<Record<string, boolean>>(
    getVisibleMetrics(metricsSummary, defaultDisplayMetrics)
  );

  const numColumns = groupedData[0].values[0].values.length;

  const getUseDarkText = (gridMetric: GridMetric) => {
    const theme = performerTheme[gridMetric.PerformerName as PerformerName];
    const team = performerTeams[gridMetric.PerformerName];
    const systemList = teamSystems[team];
    if (theme) {
      const systemNum = systemList.indexOf(gridMetric.System.replace(FINETUNED, ''));
      return systemNum === 2;
    }
  };

  const getTextColor = (gridMetric: GridMetric) => {
    const theme = performerTheme[gridMetric.PerformerName as PerformerName];
    if (theme) {
      return theme.text;
    }
  };

  const popoverContent = (gridMetric: GridMetric) => (
    <PopoverContent>
      <span>{gridMetric.Metric}</span>
      <span>Baseline value: {gridMetric.Baseline_Value?.toFixed(4)}</span>
      <span>
        {`${
          columnLabels && gridMetric[columnProperty]
            ? columnLabels[gridMetric[columnProperty]!]
            : gridMetric[columnProperty]
        } value: ${gridMetric.Value?.toFixed(4)}`}
      </span>
    </PopoverContent>
  );

  interface BaseMetric {
    key: string;
    values: any[];
  }
  const getTaskAreaGrid = (group: string) => {
    const index = groupedData.findIndex((data) => data.key === group);
    groupedData[index].values.sort((a: {key: string; values: any}, b: {key: string; values: any}) => {
      if (primaryMetrics.includes(`${groupedData[index].key}|${a.key}`)) return -1;
      else if (primaryMetrics.includes(`${groupedData[index].key}|${b.key}`)) return 1;
      return 0;
    });
    return (
      <Grid numColumns={numColumns}>
        {groupedData[index].values.map((metricData: {key: string; values: any}) => {
          if (visibleMetrics[`${groupedData[index].key}|${metricData.key}`]) {
            return (
              <React.Fragment key={`${metricData.key}-row`}>
                <MetricName key={`${metricData.key}-label`}>
                  <div style={{textAlign: 'center'}}>{metricData.key}</div>
                </MetricName>
                {metricData.values.map((gridMetric: GridMetric) => (
                  <Popover
                    content={popoverContent(gridMetric)}
                    placement="bottom"
                    key={`${gridMetric.Metric}-${gridMetric[columnProperty]}-popover`}
                  >
                    <MetricValue
                      color={getSystemColor(gridMetric.PerformerName, gridMetric.System)}
                      textColor={getTextColor(gridMetric)}
                      patternColor={getPatternColor(
                        gridMetric.PerformerName,
                        gridMetric.System,
                        !!(gridMetric.Improvement && gridMetric.Improvement < 0)
                      )}
                      isFineTuned={gridMetric.System.includes(FINETUNED)}
                      key={`${gridMetric.Metric}-${gridMetric[columnProperty]}`}
                      inverted={
                        gridMetric.Improvement &&
                        gridMetric.Improvement * getReverseMetric(gridMetric.Metric, gridMetric.Group) < 0
                      }
                      darkText={getUseDarkText(gridMetric)}
                    >
                      <CenterDiv>{gridMetric.System}</CenterDiv>
                      <PercentImprovement>
                        {gridMetric.Improvement !== null &&
                          `${
                            Math.round(gridMetric.Improvement * 100) *
                            getReverseMetric(gridMetric.Metric, gridMetric.Group)
                          }%`}
                      </PercentImprovement>
                    </MetricValue>
                  </Popover>
                ))}
              </React.Fragment>
            );
          }
          return <React.Fragment key={`${metricData.key}-skip`}></React.Fragment>;
        })}
      </Grid>
    );
  };

  const getTaskAreaGridTA3 = (group: string) => {
    const index = groupedData.findIndex((data) => data.key === group);
    groupedData[index].values.sort((a: {key: string; values: any}, b: {key: string; values: any}) => {
      if (primaryMetrics.includes(`${groupedData[index].key}|${a.key}`)) return -1;
      else if (primaryMetrics.includes(`${groupedData[index].key}|${b.key}`)) return 1;
      return 0;
    });
    return (
      <Grid numColumns={numColumns}>
        {groupedData[index].values.map((metricData: {key: string; values: any}) => {
          if (
            visibleMetrics[`${groupedData[index].key}|${metricData.key}`] &&
            metricData.key !== 'Delta Equal Error Rate' &&
            metricData.key !== 'Stylistic Consistency'
          ) {
            return (
              <React.Fragment key={`${metricData.key}-row`}>
                <MetricName key={`${metricData.key}-label`}>
                  <div style={{textAlign: 'center'}}>{metricData.key}</div>
                </MetricName>
                {metricData.values.map((gridMetric: GridMetric) => (
                  <Popover
                    content={
                      gridMetric.Baseline_Value === 0 && gridMetric.Value === 0 && gridMetric.Improvement === -1
                        ? ''
                        : popoverContent(gridMetric)
                    }
                    placement="bottom"
                    key={`${gridMetric.Metric}-${gridMetric[columnProperty]}-popover`}
                  >
                    <MetricValue
                      color={getSystemColor(gridMetric.PerformerName, gridMetric.System)}
                      textColor={
                        gridMetric.Baseline_Value === 0 && gridMetric.Value === 0 && gridMetric.Improvement === -1
                          ? 'rgba(0,0,0,0)'
                          : getTextColor(gridMetric)
                      }
                      patternColor={getPatternColor(
                        gridMetric.PerformerName,
                        gridMetric.System,
                        !!(gridMetric.Improvement && gridMetric.Improvement < 0)
                      )}
                      isFineTuned={gridMetric.System.includes(FINETUNED)}
                      key={`${gridMetric.Metric}-${gridMetric[columnProperty]}`}
                      inverted={
                        gridMetric.Improvement &&
                        gridMetric.Improvement * getReverseMetric(gridMetric.Metric, gridMetric.Group) < 0
                      }
                      darkText={getUseDarkText(gridMetric)}
                    >
                      <CenterDiv>{gridMetric.System}</CenterDiv>
                      <PercentImprovement>
                        {gridMetric.Improvement !== null &&
                          `${
                            Math.round(gridMetric.Improvement * 100) *
                            getReverseMetric(gridMetric.Metric, gridMetric.Group)
                          }%`}
                      </PercentImprovement>
                    </MetricValue>
                  </Popover>
                ))}
              </React.Fragment>
            );
          } else if (
            visibleMetrics[`${groupedData[index].key}|${metricData.key}`] &&
            metricData.key === 'Delta Equal Error Rate'
          ) {
            var baseMetrics = groupedData[1].values.filter((data: BaseMetric) =>
              data.key.includes('Delta Equal Error Rate [')
            );
            var vals = baseMetrics.map((d: BaseMetric) => {
              return d.values;
            });

            var vals_new = [];

            // Iterate over the first element of each sub-array
            for (let i = 0; i < vals[0].length; i++) {
              let temp = [];
              for (let j = 0; j < vals.length; j++) {
                temp.push(vals[j][i]);
              }
              vals_new.push(temp);
            }

            var metricDataNew1 = {
              key: 'Delta Equal Error Rate',
              SubMetrics: baseMetrics.map((d: BaseMetric) => {
                const match = d.key.match(/\[(.*?)\]/);
                return match ? match[1] : null;
              }),
              values: vals_new,
            };

            // const order = ['BBN (ALERT)', 'ISI (SADIRI)', 'Leidos (RELAX)', 'UPenn (PAUSIT)'];
            // // Function to rearrange the array based on the order array
            // // Sort the data array based on the order array
            // const rearrangedData = data.sort((a, b) => {
            //   const indexA = order.indexOf(a.PerformerName);
            //   const indexB = order.indexOf(b.PerformerName);

            //   if (indexA === -1 && indexB === -1) {
            //       return a.PerformerName.localeCompare(b.PerformerName);
            //   } else if (indexA === -1) {
            //       return 1; // Move undefined to the end
            //   } else if (indexB === -1) {
            //       return -1; // Move undefined to the end
            //   } else {
            //       return indexA - indexB;
            //   }
            // });

            if (baseMetrics.length === 1) {
              return (
                <React.Fragment key={`${metricData.key}-row`}>
                  <table style={{display: 'flex', marginBottom: '-2px'}}>
                    <tbody style={{display: 'flex'}}>
                      <tr style={{display: 'flex', marginRight: '-100px'}}>
                        <td rowSpan={1} colSpan={1} style={{display: 'flex', width: '21%', whiteSpace: 'nowrap'}}>
                          <SubMetricName
                            style={{display: 'flex', height: 'inherit', width: '100%', margin: '0px'}}
                            key={`${'Styl Stylistic Consistency'}-label`}
                          >
                            <MultiCellDiv
                              style={{
                                transform: 'rotate(-90deg)',
                                flexWrap: 'nowrap',
                                // whiteSpace: 'break-spaces',
                                height: '120px',
                                display: 'flex',
                                alignItems: 'center',
                                marginRight: '2em',
                                marginLeft: '2em',
                              }}
                            >
                              Delta Equal <br></br> Error Rate
                            </MultiCellDiv>
                          </SubMetricName>
                        </td>
                        <td rowSpan={metricDataNew1.values[0].length} style={{display: 'flex'}}>
                          {metricDataNew1.SubMetrics.map((subMetric: string) => (
                            <SubMetricName
                              style={{height: 'inherit', margin: '0px', marginLeft: '3px', width: '170px'}}
                            >
                              <MultiCellDiv>{subMetric}</MultiCellDiv>
                            </SubMetricName>
                          ))}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {metricData.values.map((gridMetric: GridMetric) => (
                    <Popover
                      content={
                        gridMetric.Baseline_Value === 0 && gridMetric.Value === 0 && gridMetric.Improvement === -1
                          ? ''
                          : popoverContent(gridMetric)
                      }
                      placement="bottom"
                      key={`${gridMetric.Metric}-${gridMetric[columnProperty]}-popover`}
                    >
                      <MetricValue
                        color={getSystemColor(gridMetric.PerformerName, gridMetric.System)}
                        textColor={
                          gridMetric.Baseline_Value === 0 && gridMetric.Value === 0 && gridMetric.Improvement === -1
                            ? 'rgba(0,0,0,0)'
                            : getTextColor(gridMetric)
                        }
                        patternColor={getPatternColor(
                          gridMetric.PerformerName,
                          gridMetric.System,
                          !!(gridMetric.Improvement && gridMetric.Improvement < 0)
                        )}
                        isFineTuned={gridMetric.System.includes(FINETUNED)}
                        key={`${gridMetric.Metric}-${gridMetric[columnProperty]}`}
                        inverted={
                          gridMetric.Improvement &&
                          gridMetric.Improvement * getReverseMetric(gridMetric.Metric, gridMetric.Group) < 0
                        }
                        darkText={getUseDarkText(gridMetric)}
                      >
                        <CenterDiv>{gridMetric.System}</CenterDiv>
                        <PercentImprovement>
                          {gridMetric.Improvement !== null &&
                            `${
                              Math.round(gridMetric.Improvement * 100) *
                              getReverseMetric(gridMetric.Metric, gridMetric.Group)
                            }%`}
                        </PercentImprovement>
                      </MetricValue>
                    </Popover>
                  ))}
                </React.Fragment>
              );
            }

            return (
              <React.Fragment key={`${metricData.key}-row`}>
                <table style={{display: 'flex', marginBottom: '-2px'}}>
                  <tbody style={{display: 'flex'}}>
                    <tr style={{display: 'flex'}}>
                      <td rowSpan={1} colSpan={1} style={{display: 'flex', width: '20%', whiteSpace: 'nowrap'}}>
                        <SubMetricName
                          style={{display: 'flex', height: 'inherit', width: '100%', margin: '0px'}}
                          key={`${'DELTA'}-label`}
                        >
                          <MultiCellDiv style={{transform: 'rotate(-90deg)', flexWrap: 'nowrap', height: '10%'}}>
                            Delta Equal Error Rate
                          </MultiCellDiv>
                        </SubMetricName>
                      </td>
                      <td
                        rowSpan={metricDataNew1.values[0].length}
                        style={{
                          height: 'inherit',
                          display: 'flex',
                          width: '50%',
                          marginRight: '-5px',
                          marginBottom: '-2px',
                          justifyContent: 'space-between',
                          flexDirection: 'column',
                        }}
                      >
                        {metricDataNew1.SubMetrics.map((subMetric: string) => (
                          <SubMetricName>
                            <MultiCellDiv>{subMetric}</MultiCellDiv>
                          </SubMetricName>
                        ))}
                      </td>
                    </tr>
                  </tbody>
                </table>

                {metricDataNew1.values.map((gridMetricList: GridMetric[]) => (
                  <Popover
                    content={''}
                    placement="bottom"
                    key={`${gridMetricList[0].Metric}-${gridMetricList[0][columnProperty]}-popover`}
                  >
                    {
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          marginBottom: '-2px',
                          marginTop: '2px',
                        }}
                      >
                        {gridMetricList.map((gridMetric: GridMetric) => (
                          <Popover
                            content={
                              gridMetric.Baseline_Value === 0 && gridMetric.Value === 0 && gridMetric.Improvement === -1
                                ? ''
                                : popoverContent(gridMetricList[0])
                            }
                            placement="bottom"
                            key={`${gridMetric.Metric}-${gridMetric[columnProperty]}-popover`}
                          >
                            <MetricValue
                              color="rgba(0,0,0,0)"
                              textColor={
                                gridMetric.Baseline_Value === 0 &&
                                gridMetric.Value === 0 &&
                                gridMetric.Improvement === -1
                                  ? 'rgba(0,0,0,0)'
                                  : getTextColor(gridMetric)
                              }
                              patternColor={getPatternColor(
                                gridMetric.PerformerName,
                                gridMetric.System,
                                !!(gridMetric.Improvement && gridMetric.Improvement < 0)
                              )}
                              isFineTuned={gridMetric.System.includes(FINETUNED)}
                              key={`${gridMetric.Metric}-${gridMetric[columnProperty]}`}
                              inverted={
                                gridMetric.Improvement &&
                                gridMetric.Improvement * getReverseMetric(gridMetric.Metric, gridMetric.Group) < 0
                              }
                              darkText={getUseDarkText(gridMetric)}
                              style={{border: '0px', padding: '0px', display: 'flex'}}
                            >
                              <CenterDiv
                                style={{
                                  border:
                                    gridMetric.Baseline_Value === 0 &&
                                    gridMetric.Value === 0 &&
                                    gridMetric.Improvement === -1
                                      ? '0px solid rgba(0,0,0,0)'
                                      : `1px solid ${getSystemColor(gridMetric.PerformerName, gridMetric.System)}`,
                                  borderRadius: '2px',
                                  width: '100%',
                                  margin: '1px',
                                  backgroundColor: ` ${
                                    gridMetric.Improvement !== null && gridMetric.Improvement > 0
                                      ? getSystemColor(gridMetric.PerformerName, gridMetric.System)
                                      : 'rgba(0,0,0,0)'
                                  }`,
                                }}
                              >
                                {gridMetric.System}
                                <PercentImprovement>
                                  {gridMetric.Improvement !== null &&
                                    `${
                                      Math.round(gridMetric.Improvement * 100) *
                                      getReverseMetric(gridMetric.Metric, gridMetric.Group)
                                    }%`}
                                </PercentImprovement>
                              </CenterDiv>
                            </MetricValue>
                          </Popover>
                        ))}
                      </div>
                    }
                  </Popover>
                ))}
              </React.Fragment>
            );
          } else if (
            visibleMetrics[`${groupedData[index].key}|${metricData.key}`] &&
            metricData.key === 'Stylistic Consistency'
          ) {
            var baseMetrics1 = groupedData[1].values.filter((data: BaseMetric) =>
              data.key.includes('Stylistic Consistency [')
            );

            var vals1 = baseMetrics1.map((d: BaseMetric) => {
              return d.values;
            });
            var vals_new_2 = [];

            // Iterate over the first element of each sub-array
            for (let i = 0; i < vals1[0].length; i++) {
              let temp = [];
              for (let j = 0; j < vals1.length; j++) {
                temp.push(vals1[j][i]);
              }
              vals_new_2.push(temp);
            }

            var metricDataNew2 = {
              key: 'Stylistic Consistency',
              SubMetrics: baseMetrics1.map((d: BaseMetric) => {
                const match = d.key.match(/\[(.*?)\]/);
                return match ? match[1] : null;
              }),
              values: vals_new_2,
            };
            return (
              <React.Fragment key={`${metricData.key}-row`}>
                <table style={{display: 'flex', marginBottom: '-2px'}}>
                  <tbody style={{display: 'flex'}}>
                    <tr style={{display: 'flex', marginRight: '-100px'}}>
                      <td rowSpan={1} colSpan={1} style={{display: 'flex', width: '21%', whiteSpace: 'nowrap'}}>
                        <SubMetricName
                          style={{display: 'flex', height: 'inherit', width: '100%', margin: '0px'}}
                          key={`${'Styl Stylistic Consistency'}-label`}
                        >
                          <MultiCellDiv
                            style={{
                              transform: 'rotate(-90deg)',
                              flexWrap: 'nowrap',
                              whiteSpace: 'break-spaces',
                              height: '120px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            Stylistic Consistency
                          </MultiCellDiv>
                        </SubMetricName>
                      </td>
                      <td rowSpan={metricDataNew2.values[0].length} style={{display: 'flex'}}>
                        {metricDataNew2.SubMetrics.map((subMetric: string) => (
                          <SubMetricName style={{height: 'inherit', margin: '0px', marginLeft: '3px', width: '170px'}}>
                            <MultiCellDiv>{subMetric}</MultiCellDiv>
                          </SubMetricName>
                        ))}
                      </td>
                    </tr>
                  </tbody>
                </table>
                {metricData.values.map((gridMetric: GridMetric) => (
                  <Popover
                    content={
                      gridMetric.Baseline_Value === 0 && gridMetric.Value === 0 && gridMetric.Improvement === -1
                        ? ''
                        : popoverContent(gridMetric)
                    }
                    placement="bottom"
                    key={`${gridMetric.Metric}-${gridMetric[columnProperty]}-popover`}
                  >
                    <MetricValue
                      color={getSystemColor(gridMetric.PerformerName, gridMetric.System)}
                      textColor={
                        gridMetric.Baseline_Value === 0 && gridMetric.Value === 0 && gridMetric.Improvement === -1
                          ? 'rgba(0,0,0,0)'
                          : getTextColor(gridMetric)
                      }
                      patternColor={getPatternColor(
                        gridMetric.PerformerName,
                        gridMetric.System,
                        !!(gridMetric.Improvement && gridMetric.Improvement < 0)
                      )}
                      isFineTuned={gridMetric.System.includes(FINETUNED)}
                      key={`${gridMetric.Metric}-${gridMetric[columnProperty]}`}
                      inverted={
                        gridMetric.Improvement &&
                        gridMetric.Improvement * getReverseMetric(gridMetric.Metric, gridMetric.Group) < 0
                      }
                      darkText={getUseDarkText(gridMetric)}
                    >
                      <CenterDiv>{gridMetric.System}</CenterDiv>
                      <PercentImprovement>
                        {gridMetric.Improvement !== null &&
                          `${
                            Math.round(gridMetric.Improvement * 100) *
                            getReverseMetric(gridMetric.Metric, gridMetric.Group)
                          }%`}
                      </PercentImprovement>
                    </MetricValue>
                  </Popover>
                ))}
              </React.Fragment>
            );
          }
          return <React.Fragment key={`${metricData.key}-skip`}></React.Fragment>;
        })}
      </Grid>
    );
  };

  const downloadMetricsGrid = useRef(null as any);
  const [{selectedGenre}] = useDataContext();
  return (
    <MetricsContainer>
      <div style={{float: 'right', padding: '0.2rem'}}>
        <Tooltip title="Download as png">
          <Button
            shape="default"
            icon={<CameraOutlined />}
            onClick={() => {
              exportComponentAsPNG(downloadMetricsGrid, {
                fileName: 'metrics_grid.png',
                html2CanvasOptions: {
                  height: downloadMetricsGrid.current.scrollHeight,
                  width: downloadMetricsGrid.current.scrollWidth + 0.01 * downloadMetricsGrid.current.scrollWidth,
                },
              });
            }}
          />
        </Tooltip>
      </div>
      <Header>
        <HeaderText>Metrics</HeaderText>
        <ErrorBoundary invisible>
          <SettingsButton
            shape="circle"
            type="ghost"
            icon={<StyledSettingsIcon />}
            onClick={() => setShowModal(true)}
          />
          <MetricsSettingsModal
            showModal={showModal}
            setShowModal={setShowModal}
            visibleMetrics={visibleMetrics}
            setVisibleMetrics={setVisibleMetrics}
          />
        </ErrorBoundary>
      </Header>

      <Table ref={downloadMetricsGrid} style={{backgroundColor: 'white'}}>
        <thead>
          <tr>
            <th></th>
            <th>
              <HeaderGrid numColumns={numColumns}>
                {groupedData[0].values[0].values.map((d: GridMetric) => (
                  <ColumnHeader key={d[columnProperty]}>
                    {columnLabels && d[columnProperty] ? columnLabels[d[columnProperty]!] : d[columnProperty]}
                  </ColumnHeader>
                ))}
              </HeaderGrid>
            </th>
          </tr>
        </thead>
        <tbody>
          {groupedData.findIndex((data) => data.key === 'TA1') !== -1 && (
            <tr>
              <TaskAreaName>
                <TaskAreaText>Feature Space Generation</TaskAreaText>
              </TaskAreaName>
              <td>{getTaskAreaGrid('TA1')}</td>
            </tr>
          )}
          {groupedData.findIndex((data) => data.key === 'TA2') !== -1 && (
            <tr>
              <TaskAreaName>
                <TaskAreaText>Text Attribution</TaskAreaText>
              </TaskAreaName>
              <td>{getTaskAreaGrid('TA2')}</td>
            </tr>
          )}
          {groupedData.findIndex((data) => data.key === 'TA3') !== -1 && (
            <tr style={{display: selectedGenre && selectedGenre.includes('cross') ? '' : 'none'}}>
              <TaskAreaName>
                <TaskAreaText>Privacy Preservation</TaskAreaText>
              </TaskAreaName>
              <td>{getTaskAreaGridTA3('TA3')}</td>
            </tr>
          )}
        </tbody>
      </Table>
    </MetricsContainer>
  );
};

export const getVisibleMetrics = (metricsSummary: MetricsSummary[] | null, defaultDisplayMetrics: string[]) => {
  const visibleMetrics: Record<string, boolean> = {};
  if (metricsSummary) {
    for (let metricsGroup of metricsSummary) {
      for (let metric of metricsGroup.MetricsList) {
        visibleMetrics[`${metricsGroup.Group}|${metric}`] = defaultDisplayMetrics.includes(
          `${metricsGroup.Group}|${metric}`
        );
      }
    }
  }
  return visibleMetrics;
};

export const getGroupedData = (data: GridMetric[]) => {
  return nest()
    .key(function (d) {
      return (d as GridMetric).Group;
    })
    .key(function (d) {
      return (d as GridMetric).Metric;
    })
    .entries(data);
};

const MetricsContainer = styled.div`
  box-shadow: ${(props: any) => `3px 3px 1px ${props.theme.colors.neutral0Shadow}`};
  border: ${(props: any) => `1px solid ${props.theme.colors.neutral0Shadow}`};
  border-radius: 2px;
  padding: 0.5rem 0.5rem 3rem 0.5rem;
  margin-bottom: 1.5rem;
  flex: 1;
`;

const Table = styled.table`
  border-spacing: 8px;
  border-collapse: separate;
  height: 100%;
  width: 100%;
  font-size: 14px;
`;

interface GridProps {
  numColumns: number;
}

const HeaderGrid = styled.div<GridProps>`
  display: grid;
  grid-template-columns: ${(props: GridProps) => `repeat(${props.numColumns}, 1fr)`};
  row-gap: 4px;
  column-gap: 4px;
  box-shadow: ${(props: any) => `3px 3px 1px ${props.theme.colors.neutral0Shadow}`};
  border: ${(props: any) => `1px solid ${props.theme.colors.neutral0Shadow}`};
  border-radius: 2px;
  margin-left: 252px;
`;

const Grid = styled.div<GridProps>`
  display: grid;
  grid-template-columns: ${(props: GridProps) => `250px repeat(${props.numColumns}, 1fr)`};
  row-gap: 4px;
  column-gap: 4px;
  margin: -4px 0;
  height: 100%;
`;

const ColumnHeader = styled.div`
  padding: 2px;
  font-size: 12px;
  width: 120px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const TaskAreaName = styled.td`
  box-shadow: ${(props: any) => `3px 3px 1px ${props.theme.colors.neutral0Shadow}`};
  border: ${(props: any) => `1px solid ${props.theme.colors.neutral0Shadow}`};
  border-radius: 2px;
  padding: 1rem;
  height: 250px;
  font-family: 'Archivo';
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  width: 4rem;
`;

const TaskAreaText = styled.div`
  transform: rotate(-90deg);
  margin-left: -10em;
  margin-right: -10em;
`;

const MetricName = styled.div`
  box-shadow: ${(props: any) => `2px 2px 1px ${props.theme.colors.neutral0Shadow}`};
  border: ${(props: any) => `1px solid ${props.theme.colors.neutral0Shadow}`};
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
`;

const SubMetricName = styled.div`
  box-shadow: ${(props: any) => `2px 2px 1px ${props.theme.colors.neutral0Shadow}`};
  border: ${(props: any) => `1px solid ${props.theme.colors.neutral0Shadow}`};
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  height: 50px;
  padding: 0px;
  margin: 3px;
`;

const MultiCellDiv = styled.div`
  text-align: center;
  margin: 5px;
  margin-left: 10px;
`;

const MetricValue = styled.div<any>`
  background: ${(props: any) => (props.inverted ? props.theme.colors.neutral0 : props.color)};
  border-radius: 2px;
  text-transform: 'uppercase';
  color: ${(props: any) =>
    props.inverted ? props.textColor : props.darkText ? props.theme.colors.textPrimary : props.theme.colors.neutral0};
  border: ${(props: any) => props.inverted && `1px solid ${props.textColor}`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: ${(props: any) =>
    props.isFineTuned &&
    (props.inverted
      ? `radial-gradient(${props.patternColor} 1px, ${props.theme.colors.neutral0} 1px)`
      : `radial-gradient(${props.patternColor} 1px, ${props.color} 1px)`)};
  background-size: ${(props: any) => props.isFineTuned && '4px 4px'};
  padding: 0px 8px;

  &:hover {
    cursor: pointer;
  }
`;

const PercentImprovement = styled.div`
  font-weight: bold;
  margin-top: 8px;
`;

const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
`;

const SettingsButton = styled(Button)`
  min-width: 20px;
  width: 28px;
  height: 28px;
  margin-left: 8px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 0px 0px 8px;
`;

const StyledSettingsIcon = styled(SettingOutlined)`
  svg {
    width: 16px;
    height: 16px;
  }
`;

const CenterDiv = styled.div`
  text-align: center;
`;
